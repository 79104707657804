<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Categories Module</h4>
        </v-col>
      </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap class="whiteBg">
            <v-col cols="12">
              <v-layout wrap>
                <h5>All Categories</h5>
              </v-layout>

              <v-layout wrap>
                    <v-col cols="4">
                    
                    <!-- Create Category -->
                    <v-dialog v-model="dialogCreateAccount" width="350">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on"
                            >Add a Category
                            <v-icon class="ml-3" color="#72BD61"
                            >mdi-account-plus-outline</v-icon
                            ></v-btn
                        >
                        </template>

                        <v-card>
                        <v-card-text>
                             <v-layout wrap class="mt-3">
                                <v-progress-linear
                                    class="mt-3"
                                    color="#7CBF46"
                                    v-show="loader"
                                    :indeterminate="true"
                                ></v-progress-linear>
                            </v-layout>
                            <v-layout wrap>
                            <v-col class="text-h6" cols="12" align="center">
                                Create a Category
                            </v-col>
                            </v-layout>
                            <v-row>
                            <v-col cols="12">
                                <v-text-field
                                dense
                                outlined
                                v-model="catData.name"
                                prepend-inner-icon="mdi-account-plus-outline"
                                label="Category Name"
                                ></v-text-field>
                            </v-col>
                            </v-row>
                            <v-layout wrap>
                            <v-col cols="12" align="center">
                                <v-btn class="buttonStyle" @click="createCategory">
                                Create Catergory
                                </v-btn>
                            </v-col>
                            </v-layout>
                        </v-card-text>
                        </v-card>
                    </v-dialog>
                    
                    <!-- Edit Dailog--->
                    <v-dialog v-model="dialogEditCategory" width="350">
                        <v-card>
                        <v-card-text>
                             <v-layout wrap class="mt-3">
                                <v-progress-linear
                                    class="mt-3"
                                    color="#7CBF46"
                                    v-show="loader"
                                    :indeterminate="true"
                                ></v-progress-linear>
                            </v-layout>
                            <v-layout wrap>
                                <v-col class="text-h6" cols="12" align="center">
                                    Edit Category {{singleCategory.name}}
                                </v-col>
                            </v-layout>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                    dense
                                    outlined
                                    v-model="singleCategory.name"
                                    prepend-inner-icon="mdi-account-plus-outline"
                                    label="Category Name"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-layout wrap>
                            <v-col cols="12" align="center">
                                <v-btn class="buttonStyle" @click="updateCategory">
                                Update Catergory
                                </v-btn>
                            </v-col>
                            </v-layout>
                        </v-card-text>
                        </v-card>
                    </v-dialog>

                    <!-- Delete Dailog--->
                    <v-dialog v-model="dialogDeleteCategory" width="450">
                        <v-card>
                        <v-card-text>
                             <v-layout wrap class="mt-3">
                                <v-progress-linear
                                    class="mt-3"
                                    color="#7CBF46"
                                    v-show="loader"
                                    :indeterminate="true"
                                ></v-progress-linear>
                            </v-layout>
                            <v-layout wrap>
                                <v-col cols="12" align="center">
                                   <p>Are you sure you want to delete category with name: {{singleCategory.name}} </p>
                                </v-col>
                            </v-layout>
                            <v-layout wrap>
                            <v-col cols="12" align="center">
                                <v-btn class="buttonStyle" small @click="deleteCategory">
                                Yes
                                </v-btn>

                                <v-btn class="ml-2" dark color="red" small @click="closeDeleteDialog">
                                No
                                </v-btn>
                            </v-col>
                            </v-layout>
                        </v-card-text>
                        </v-card>
                    </v-dialog>

                    <!--- Sucessfully Category creation -->
                    <v-dialog v-model="dialogSuccess" width="350" persistent>
                        <v-card>
                        <v-card-text>
                            <v-layout>
                            <v-col cols="12" align="center">
                                <v-icon color="#72BD61" size="50"
                                >mdi-check-circle</v-icon
                                >
                            </v-col>
                            </v-layout>
                            <v-layout wrap>
                            <v-col class="text-h6" cols="12" align="center">
                                Category Created Successfully
                            </v-col>
                            </v-layout>
                            <v-layout wrap>
                            <v-col cols="12" align="center">
                                You have Successfully created a category.
                            </v-col>
                            </v-layout>
                            <v-layout wrap>
                            <v-col cols="12" align="center">
                                <v-btn
                                class="buttonStyle"
                                @click="dialogSuccess = false"
                                >
                                Ok
                                </v-btn>
                            </v-col>
                            </v-layout>
                        </v-card-text>
                        </v-card>
                    </v-dialog>
                    </v-col>
              </v-layout>

              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="400px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Category Name
                          </th>
                          <th class="text-left">
                            Product Count
                          </th>
                          <th class="text-left">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                         <tr v-for="category in Categories" :key="category.productCategoryId">
                            <td>{{category.name}}</td>
                            <td>{{category.Products.length}}</td>
                            <td>
                                <v-btn small color="black" dark @click="openEditDialog(category)">Edit</v-btn>
                                <v-btn small class="ml-2" color="red" dark @click="openDeleteDialog(category)">Delete</v-btn>
                            </td>
                         </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
export default {
  name: "AdminCategoryModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      dialogCreateAccount:false,
      dialogSuccess:false,
      dialogEditCategory:false,
      dialogDeleteCategory:false,
      loader:false,
      systemSetting: {
        systemCurrency: "GH₵",
      },
      Categories:[],
      singleCategory:{
          productCategoryId:"",
          name:"",
      },
      catData:{
          name:""
      }
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
       this.Categories = []; 
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
         this.Categories  = response.data.data;
        });
    },

    createCategory() {
        this.loader = true;
        this.errorMessage = "";

        this.$http
            .post(`${process.env.VUE_APP_URL}product-category`, this.catData)
            .then(() => {
            this.loader = false;
            this.getCategories();
            this.dialogCreateAccount = false;
            this.dialogSuccess = true;
            this.catData.name="";
            })
            .catch((error) => {
            this.loader = false;
            if (error.response.data.data.length >= 1) {
                this.errorMessage = error.response.data.data.toString();
            }
        });
    },

    openEditDialog(category){
        this.dialogEditCategory = true;
        this.singleCategory = category;
    },

    updateCategory(){
        
        this.loader = true;
        this.errorMessage = "";

        const data = {
            name : this.singleCategory.name
        }

        this.$http
            .put(`${process.env.VUE_APP_URL}product-category/` + this.singleCategory.productCategoryId, data)
            .then(() => {
            this.loader = false;
            this.getCategories();
            this.dialogEditCategory = false;
            })
            .catch((error) => {
            this.loader = false;
            if (error.response.data.data.length >= 1) {
                this.errorMessage = error.response.data.data.toString();
            }
        });
    },

    openDeleteDialog(category){
        this.dialogDeleteCategory = true;
        this.singleCategory = category;
    },

    closeDeleteDialog(){
        this.dialogDeleteCategory = false;
        this.singleCategory = {};
    },

    deleteCategory(){
        this.loader = true;
        this.errorMessage = "";

        this.$http
            .delete(`${process.env.VUE_APP_URL}product-category/` + this.singleCategory.productCategoryId)
            .then(() => {
            this.loader = false;
            this.getCategories();
            this.dialogDeleteCategory = false;
            })
            .catch((error) => {
            this.loader = false;
            if (error.response.data.data.length >= 1) {
                this.errorMessage = error.response.data.data.toString();
            }
        });
    }
  }
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}
</style>
